var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',[_c('span',{staticClass:"content-count"},[_c('span',{class:_vm.content_count[_vm.type] > _vm.tier.benefits[_vm.type]
						? 'red'
						: _vm.content_count[_vm.type] == _vm.tier.benefits[_vm.type]
							? 'neutral-2'
							: 'green'},[_vm._v(_vm._s(_vm.content_count[_vm.type]))]),_c('span',{staticClass:"divider"},[_vm._v("/")]),(_vm.tier.benefits[_vm.type] == 'infinite')?_c('i',{staticClass:"far fa-infinity",attrs:{"aria-hidden":"true"}}):[_vm._v(_vm._s(_vm.tier.benefits[_vm.type]))]],2),_vm._v("\n\t\t"+_vm._s(_vm.type === "npcs" ? `${_vm.type.substring(0, 3).toUpperCase()}s` : _vm.type.capitalize())+"\n\t")]),_c('div',[_vm._t("actions-left"),(
				_vm.tier.benefits[_vm.type] === 'infinite' ||
				(!_vm.overencumbered && _vm.content_count[_vm.type] < _vm.tier.benefits[_vm.type])
			)?[_vm._t("actions-right"),(_vm.type === 'campaigns')?_c('a',{staticClass:"btn btn-sm bg-neutral-5",on:{"click":function($event){return _vm.$emit('add')}}},[_c('i',{staticClass:"fas fa-plus green",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"d-none d-md-inline-block ml-1"},[_vm._v("New "+_vm._s(_vm.type.slice(0, -1)))])]):[(_vm.type === 'characters')?_c('button',{staticClass:"btn btn-sm bg-neutral-5",on:{"click":_vm.add}},[_c('i',{staticClass:"fas fa-plus green",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"d-none d-md-inline-block ml-1"},[_vm._v("New "+_vm._s(_vm.type.slice(0, -1)))])]):_c('router-link',{staticClass:"btn btn-sm bg-neutral-5",attrs:{"to":`${_vm.$route.path}/add-${_vm.type.slice(0, -1)}`}},[_c('i',{staticClass:"fas fa-plus green",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"d-none d-md-inline-block ml-1"},[_vm._v("New "+_vm._s(_vm.type.slice(0, -1)))])])]]:(_vm.overencumbered)?_c('router-link',{staticClass:"btn btn-sm ml-1",attrs:{"to":"/content/manage"}},[_c('i',{staticClass:"fas fa-box-full red",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"d-none d-md-inline-block ml-1"},[_vm._v("Over encumbered")])]):_c('router-link',{staticClass:"btn btn-sm ml-1",attrs:{"to":"/pricing"}},[_c('i',{staticClass:"fab fa-patreon patreon-red",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"d-none d-md-inline-block ml-1"},[_vm._v("Get more slots")])])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }