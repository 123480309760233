var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tier)?_c('hk-card',[_c('ContentHeader',{attrs:{"type":"players"}}),(!_vm.loading_players)?_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"neutral-2"},[_vm._v("These are the players you can use in your campaigns.")]),(_vm.players.length)?[_c('q-input',{attrs:{"dark":_vm.$store.getters.theme !== 'light',"borderless":"","filled":"","square":"","debounce":"300","clearable":"","placeholder":"Search players"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('q-icon',{attrs:{"slot":"prepend","name":"search"},slot:"prepend"})],1),_c('q-table',{attrs:{"data":_vm.players,"columns":_vm.columns,"row-key":"key","card-class":"bg-none","flat":"","dark":_vm.$store.getters.theme !== 'light',"loading":_vm.loading_players,"separator":"none","pagination":{ rowsPerPage: 15 },"filter":_vm.search,"wrap-cells":""},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('q-tr',{attrs:{"props":props}},_vm._l((props.cols),function(col){return _c('q-td',{key:col.name,style:(col.name === 'avatar' && _vm.avatar(props.row)
									? `background-image: url('${_vm.avatar(props.row)}')`
									: ''),attrs:{"props":props,"auto-width":col.name !== 'name'}},[(col.name === 'avatar')?[(!_vm.avatar(props.row))?_c('i',{staticClass:"hki-player",attrs:{"aria-hidden":"true"}}):_vm._e()]:(col.name !== 'actions')?[(col.name === 'name')?_c('router-link',{attrs:{"to":`${_vm.$route.path}/${props.key}`}},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(col.value)+"\n\t\t\t\t\t\t\t\t")]):[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(col.value)+"\n\t\t\t\t\t\t\t\t")]]:_c('div',{staticClass:"text-right d-flex justify-content-end"},[(_vm.tier.price !== 'Free' && Object.keys(_vm.sync_characters).length)?[(
											!props.row.sync_character || !(props.row.sync_character in _vm.sync_characters)
										)?_c('button',{staticClass:"btn btn-sm bg-neutral-5",on:{"click":function($event){return _vm.linkDialog(props.key)}}},[_c('i',{staticClass:"fas fa-link",attrs:{"aria-hidden":"true"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\tLink Character to Sync with\n\t\t\t\t\t\t\t\t\t\t")])],1):[_c('a',{staticClass:"btn btn-sm bg-neutral-5",attrs:{"href":props.row.sync_character,"target":"_blank","rel":"noopener"}},[_c('i',{staticClass:"fas fa-external-link",attrs:{"aria-hidden":"true"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\tOpen linked character sheet\n\t\t\t\t\t\t\t\t\t\t\t")])],1),_c('button',{staticClass:"btn btn-sm bg-neutral-5 ml-2",on:{"click":function($event){return _vm.syncCharacter(props.key, props.row.sync_character)}}},[_c('i',{staticClass:"fas fa-sync-alt fade-color",class:{
													rotate: props.key in _vm.syncing,
													green: _vm.syncing[props.key] === 'success',
													red: _vm.syncing[props.key] === 'error',
												},attrs:{"aria-hidden":"true"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\tUpdate with Character Sync\n\t\t\t\t\t\t\t\t\t\t\t")])],1)],_c('q-separator',{staticClass:"ml-2",attrs:{"vertical":"","dark":_vm.$store.getters.theme === 'dark'}})]:_vm._e(),_c('router-link',{staticClass:"btn btn-sm bg-neutral-5 mx-2",attrs:{"to":`${_vm.$route.path}/${props.key}`}},[_c('i',{staticClass:"fas fa-pencil",attrs:{"aria-hidden":"true"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v(" Edit ")])],1),_c('a',{staticClass:"btn btn-sm bg-neutral-5",on:{"click":function($event){return _vm.confirmDelete($event, props.key, props.row)}}},[_c('i',{staticClass:"fas fa-trash-alt",attrs:{"aria-hidden":"true"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v(" Delete ")])],1)],2)],2)}),1)]}}],null,false,3373071661)},[_c('div',{attrs:{"slot":"no-data"},slot:"no-data"}),_c('hk-loader',{attrs:{"slot":"loading","name":"players"},slot:"loading"})],1)]:_vm._e(),(!_vm.players.length && !_vm.overencumbered)?_c('router-link',{staticClass:"btn btn-lg bg-neutral-5",attrs:{"to":"/content/players/add-player"}},[_c('i',{staticClass:"fas fa-plus green mr-1",attrs:{"aria-hidden":"true"}}),_vm._v(" Create your first player\n\t\t")]):(_vm.tier.price === 'Free')?_c('router-link',{staticClass:"btn bg-neutral-8 btn-block",attrs:{"to":"/pricing"}},[_vm._v("\n\t\t\tGet more player slots\n\t\t")]):_vm._e()],2):_c('hk-loader',{attrs:{"name":"players"}}),_c('q-dialog',{on:{"before-hide":function($event){_vm.link_character = undefined}},model:{value:(_vm.link_dialog),callback:function ($$v) {_vm.link_dialog=$$v},expression:"link_dialog"}},[_c('hk-link-character',{on:{"link":_vm.linkCharacter}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }