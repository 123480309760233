import { render, staticRenderFns } from "./Board.vue?vue&type=template&id=1c88be11&scoped=true"
import script from "./Board.vue?vue&type=script&lang=js"
export * from "./Board.vue?vue&type=script&lang=js"
import style0 from "./Board.vue?vue&type=style&index=0&id=1c88be11&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c88be11",
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QTooltip,QDialog,QForm,QBtn,QBtnToggle});qInstall(component, 'directives', {ClosePopup});
