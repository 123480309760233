import { render, staticRenderFns } from "./General.vue?vue&type=template&id=5f34f544&scoped=true"
import script from "./General.vue?vue&type=script&lang=js"
export * from "./General.vue?vue&type=script&lang=js"
import style0 from "./General.vue?vue&type=style&index=0&id=5f34f544&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f34f544",
  null
  
)

export default component.exports
import QForm from 'quasar/src/components/form/QForm.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QForm,QBtn,QIcon,QTooltip,QList,QItem,QItemSection,QInput,QDialog,QCard,QToolbar,QSpace,QBtnDropdown,QItemLabel});qInstall(component, 'directives', {ClosePopup});
