var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.campaign.sharing)?_c('div',{staticClass:"full-height"},[(!_vm.broadcast.live)?_c('div',{staticClass:"not-live"},[_c('p',[_vm._v("Go live to share with your players")]),_c('div',{staticClass:"live pointer",on:{"click":function($event){return _vm.setDrawer({
					show: true,
					type: 'drawers/Broadcast',
					data: { campaign_id: _vm.campaignId, private: _vm.campaign.private },
				})}}},[_vm._v("\n\t\t\tGo live\n\t\t")])]):_vm._e(),_c('q-tabs',{attrs:{"dark":_vm.$store.getters.theme === 'dark',"indicator-color":"transparent","inline-label":"","outside-arrows":"","mobile-arrows":"","no-caps":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function({ name, label, icon }){return _c('q-tab',{key:name,attrs:{"name":name,"icon":icon,"label":label}},[(_vm.showBadge(name))?_c('q-badge',{staticClass:"bg-red",attrs:{"floating":""}},[_vm._v(" Active ")]):_vm._e()],1)}),1),_c('q-tab-panels',{staticClass:"bg-transparent",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('q-tab-panel',{attrs:{"name":"background"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ valid }){return [_c('hk-input',{staticClass:"mb-2",attrs:{"label":"Background image","name":"Background image","rules":"url","clearable":"","disable":_vm.disableBackground('image')},on:{"input":(value) => {
							if (!value) {
								_vm.$delete(_vm.background, 'image');
							}
						}},model:{value:(_vm.background.image),callback:function ($$v) {_vm.$set(_vm.background, "image", $$v)},expression:"background.image"}},[_c('hk-icon',{attrs:{"slot":"prepend","icon":"fas fa-image"},slot:"prepend"})],1),_c('hk-input',{staticClass:"mb-2",attrs:{"label":"Background video","name":"Background video","rules":"url","clearable":"","disable":_vm.disableBackground('video')},on:{"input":(value) => {
							if (!value) {
								_vm.$delete(_vm.background, 'video');
							}
						}},model:{value:(_vm.background.video),callback:function ($$v) {_vm.$set(_vm.background, "video", $$v)},expression:"background.video"}},[_c('hk-icon',{attrs:{"slot":"prepend","icon":"fas fa-video"},slot:"prepend"})],1),_c('hk-input',{staticClass:"mb-2",attrs:{"label":"Background Youtube video","name":"Background youtube","rules":"url","clearable":"","disable":_vm.disableBackground('youtube')},on:{"input":(value) => {
							if (!value) {
								_vm.$delete(_vm.background, 'youtube');
							}
						}},model:{value:(_vm.background.youtube),callback:function ($$v) {_vm.$set(_vm.background, "youtube", $$v)},expression:"background.youtube"}},[_c('hk-icon',{attrs:{"slot":"prepend","icon":"fab fa-youtube"},slot:"prepend"})],1),_c('div',{staticClass:"actions"},[_c('button',{staticClass:"btn bg-neutral-5",on:{"click":_vm.clearBackground}},[_vm._v("Clear")]),_c('button',{staticClass:"btn",attrs:{"disabled":_vm.isEmpty(_vm.background) || !valid},on:{"click":function($event){valid ? _vm.setBackground() : null}}},[_vm._v("\n\t\t\t\t\t\tSet\n\t\t\t\t\t")])])]}}],null,false,310508488)})],1),_c('q-tab-panel',{attrs:{"name":"share"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ valid }){return [_c('hk-input',{staticClass:"mb-2",attrs:{"label":"Share image","name":"Share image","rules":"url","clearable":"","disable":_vm.disableShare('image')},on:{"input":(value) => {
							if (!value) {
								_vm.$delete(_vm.share, 'image');
							}
						}},model:{value:(_vm.share.image),callback:function ($$v) {_vm.$set(_vm.share, "image", $$v)},expression:"share.image"}},[_c('hk-icon',{attrs:{"slot":"prepend","icon":"fas fa-image"},slot:"prepend"})],1),_c('hk-input',{staticClass:"mb-2",attrs:{"label":"Share Youtube video","name":"Share Youtube video","rules":"url","clearable":"","disable":_vm.disableShare('youtube')},on:{"input":(value) => {
							if (!value) {
								_vm.$delete(_vm.share, 'youtube');
							}
						}},model:{value:(_vm.share.youtube),callback:function ($$v) {_vm.$set(_vm.share, "youtube", $$v)},expression:"share.youtube"}},[_c('hk-icon',{attrs:{"slot":"prepend","icon":"fab fa-youtube"},slot:"prepend"})],1),_c('hk-input',{staticClass:"mb-2",attrs:{"label":"Share message","name":"Share message","type":"textarea","rules":"max:1000","maxlength":"1001","clearable":"","disable":_vm.disableShare('message')},on:{"input":(value) => {
							if (!value) {
								_vm.$delete(_vm.share, 'message');
							}
						}},model:{value:(_vm.share.message),callback:function ($$v) {_vm.$set(_vm.share, "message", $$v)},expression:"share.message"}}),_c('div',{staticClass:"actions"},[_c('button',{staticClass:"btn btn-block",attrs:{"disabled":_vm.isEmpty(_vm.share) || !valid},on:{"click":function($event){valid ? _vm.startShare() : null}}},[_vm._v("\n\t\t\t\t\t\tShare\n\t\t\t\t\t")])])]}}],null,false,1133422653)})],1),_c('q-tab-panel',{attrs:{"name":"weather"}},[_c('Weather',{staticClass:"pb-4",attrs:{"disabled":_vm.tier.price === 'Free'},model:{value:(_vm.weather),callback:function ($$v) {_vm.weather=$$v},expression:"weather"}}),_c('div',{staticClass:"actions"},[(_vm.tier.price !== 'Free')?[_c('button',{staticClass:"btn bg-neutral-5",on:{"click":_vm.clearWeather}},[_vm._v("Clear")]),_c('button',{staticClass:"btn",attrs:{"disabled":_vm.isEmpty(_vm.weather)},on:{"click":_vm.setWeather}},[_vm._v("Set")])]:[(!_vm.isEmpty(_vm.weather))?_c('button',{staticClass:"btn bg-neutral-5",on:{"click":_vm.clearWeather}},[_vm._v("\n\t\t\t\t\t\tClear\n\t\t\t\t\t")]):_c('router-link',{staticClass:"btn bg-patreon-red",attrs:{"to":"/pricing"}},[_vm._v("Get a subscription")])]],2)],1)],1)],1):_c('Sharing',{on:{"stop":_vm.stopShare}})
}
var staticRenderFns = []

export { render, staticRenderFns }